import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import { CompanyLoginDto } from "model/Login";
import {
  CompanyDto,
  CompanyOBSetting,
  CompanyRequestDto,
  CompanyUser,
  CompanyUserRequestDto,
  StampClockTemplate,
  TemplateForAutoGeneratedGig,
  UserRoleRequest,
} from "model/Company";
import { application } from "./application.service";
import { gig } from "./gig.service";
import { companyEmployees } from "./companyEmployees";
import { calendar } from "./calendar.service";
import { template } from "./template.service";
import { worker } from "./worker.service";
import { timeReport } from "./timeReport.service";
import { workpool } from "./workpool.service";
import { staffingWorkerPool } from "./staffingWorkerPool.service";
import { CompanyFavoriteDto } from "../../../model/Favorite";
import { TimeReportPaymentDto } from "model/TimeReport";

const login = async (uid: string): Promise<AxiosResponse<CompanyLoginDto>> => {
  return await axiosRequest.auth.get<CompanyLoginDto>(
    `${API_ROOT}/login/company/${uid}`
  );
};

const addOrUpdateCompany = async (
  companyData: CompanyRequestDto,
  companyId?: number
): Promise<AxiosResponse<CompanyDto>> => {
  if (companyId) {
    return await axiosRequest.auth.put<CompanyDto>(
      `${API_ROOT}/companies/${companyId}`,
      companyData
    );
  } else {
    return await axiosRequest.auth.post<CompanyDto>(
      `${API_ROOT}/companies`,
      companyData
    );
  }
};

const addCompanyFavorites = async (
  companyId: number,
  workerId: number
): Promise<AxiosResponse<CompanyFavoriteDto>> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/favorites`,
    { workerId }
  );
};

const addCompanyFavoritesByNumber = async (
  companyId: number,
  data: any
): Promise<AxiosResponse<CompanyFavoriteDto>> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/favorites`,
    data
  );
};

const updateCompanyFavorite = async (
  companyId: number,
  data: any,
  favoriteId: number
): Promise<AxiosResponse<CompanyFavoriteDto>> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/favorites/${favoriteId}`,
    data
  );
};

const removeCompanyFavorites = async (
  companyId: number,
  id: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.delete(
    `${API_ROOT}/companies/${companyId}/favorites/${id}`
  );
};

const getCompanyFavorites = async (
  companyId: number
): Promise<AxiosResponse<CompanyFavoriteDto[]>> => {
  return await axiosRequest.auth.get<CompanyFavoriteDto[]>(
    `${API_ROOT}/companies/${companyId}/favorites`
  );
};

const getCompanyUsers = async (
  companyId: number
): Promise<AxiosResponse<CompanyUser[]>> => {
  return await axiosRequest.auth.get<CompanyUser[]>(
    `${API_ROOT}/companies/${companyId}/users`
  );
};
const getCompanySubscriptionInfo = async (
  companyId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/membership`
  );
};

const addCompanyUser = async (
  companyId: number,
  user: CompanyUserRequestDto
): Promise<AxiosResponse<CompanyUser>> => {
  return await axiosRequest.auth.post(
    `${API_ROOT}/companies/${companyId}/users`,
    user
  );
};

const updateCompanyUser = async (
  companyId: number,
  userId: number,
  firebaseId: string,
  role: UserRoleRequest,
  firstName: string,
  lastName: string
): Promise<AxiosResponse<CompanyUser>> => {
  return await axiosRequest.auth.put(
    `${API_ROOT}/companies/${companyId}/users/${userId}/role`,
    { firebaseId, role, firstName, lastName }
  );
};

const deleteCompanyUser = async (
  companyId: number,
  userId: number
): Promise<AxiosResponse<CompanyUser>> => {
  return await axiosRequest.auth.delete(
    `${API_ROOT}/companies/${companyId}/users/${userId}`
  );
};

const createPayoutForTimeReport = async (
  companyId: number,
  timeReportId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.post<TimeReportPaymentDto>(
    `${API_ROOT}/companies/${companyId}/timereports/${timeReportId}/pay`,
    { timeReportId: timeReportId, companyId: companyId }
  );
};

const getCompanyInformation = async (orgNumber: string) => {
  return axiosRequest.auth.get<any>(
    `${API_ROOT}/CompanyInfo?organisationNumber=${orgNumber}`
  );
};

const setupSimflexityPayForCompany = async (
  companyId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.post(
    `${API_ROOT}/company/${companyId}/SimflexityPay`
  );
};

const addCompanyObSetting = async (
  companyId: number,
  obSetting: CompanyOBSetting
): Promise<AxiosResponse<CompanyOBSetting>> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/OBSetting`,
    obSetting
  );
};

const getCompanyObSettings = async (
  companyId: number
): Promise<AxiosResponse<CompanyOBSetting[]>> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/OBSetting`
  );
};

const deleteCompanyObSettings = async (
  companyId: number,
  obSettingId: number
): Promise<AxiosResponse<CompanyOBSetting[]>> => {
  return await axiosRequest.auth.delete<any>(
    `${API_ROOT}/companies/${companyId}/OBSetting/${obSettingId}`
  );
};

const getTimeReportForStamping = async (
  companyId: number,
  workerPhoneNumber: string
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/${workerPhoneNumber}`
  );
};

const getStampClockTemplate = async (
  companyId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/template`
  );
};

const updateStampClockTemplate = async (
  data: StampClockTemplate
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${data.companyId}/stampClock/template`,
    data
  );
};

const getLocalStampClockOnlySetting = async (
  companyId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/settings/localOnly`
  );
};

const updateLocalStampClockOnlySetting = async (
  companyId: number,
  active: boolean
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/settings/localOnly?active=${active}`
  );
};

const getAutogenerateShiftSetting = async (
  companyId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/settings/autoGenerate`
  );
};

const updateAutogenerateShiftSetting = async (
  companyId: number,
  active: boolean
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/settings/autoGenerate?active=${active}`
  );
};

const getTemplateForAutoGeneratedGig = async (
  companyId: number,
  workerPhoneNumber: string
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/${workerPhoneNumber}/autoGenerate`
  );
};

const createAutoGeneratedGig = async (
  companyId: number,
  workerPhoneNumber: string,
  templateForAutoGeneratedGig: TemplateForAutoGeneratedGig
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/${workerPhoneNumber}/autoGenerate/${templateForAutoGeneratedGig.templateId}`
  );
};

const handleCheckInCheckOut = async (
  companyId: number,
  timeReportId: number,
  workerPhoneNumber: string,
  now: boolean
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/stampClock/${workerPhoneNumber}/timereports/${timeReportId}?now=${now}`
  );
};

const deleteCompaniesGigsShift = async (
  companyId: number,
  shiftId: number,
  gigsId: string
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.delete<any>(
    `${API_ROOT}/companies/${companyId}/gigs/${gigsId}/shifts/${shiftId}`
  );
};

const getTimeTotalMonthly = async (
  companyId: number,
  year: number,
  month: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/workers/timeSummary/${year}-${month}`
  );
};

const getTimeTotalMonthlyWorker = async (
  companyId: number,
  workerId: number,
  year: number,
  month: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/workers/${workerId}/timeSummary/${year}-${month}`
  );
};

export const company = {
  application,
  gig,
  calendar,
  template,
  worker,
  timeReport,
  login,
  workpool,
  companyEmployees,
  addOrUpdateCompany,
  getCompanyFavorites,
  addCompanyFavorites,
  removeCompanyFavorites,
  createPayoutForTimeReport,
  getCompanyUsers,
  addCompanyUser,
  updateCompanyUser,
  deleteCompanyUser,
  getCompanyInformation,
  setupSimflexityPayForCompany,
  addCompanyObSetting,
  getCompanyObSettings,
  deleteCompanyObSettings,
  addCompanyFavoritesByNumber,
  updateCompanyFavorite,
  getTimeReportForStamping,
  handleCheckInCheckOut,
  staffingWorkerPool,
  deleteCompaniesGigsShift,
  getCompanySubscriptionInfo,
  getTimeTotalMonthly,
  getTimeTotalMonthlyWorker,
  getStampClockTemplate,
  updateStampClockTemplate,
  getLocalStampClockOnlySetting,
  updateLocalStampClockOnlySetting,
  getAutogenerateShiftSetting,
  updateAutogenerateShiftSetting,
  getTemplateForAutoGeneratedGig,
  createAutoGeneratedGig,
};
