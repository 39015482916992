import styled from "styled-components";
import { Color } from "config/colors";
import { FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";

export const ArrowSign = styled.span`
  padding: 0 10px;
  margin: 0;
  font-size: ${FontSize.H4};

  &:hover {
    background-color: ${Color.SeaBlue200};
    cursor: pointer;
  }
`;

export const MonthSelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FilterModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 370px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  p {
    margin-bottom: 0px;
  }
`;

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextRowPersonnelCarrier = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;

export const FilterButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  width: 50%;
  height: 40px;
  background-color: ${Color.BurntSienna};
  margin: 5px;
  border-radius: 10px;
  margin-left: 0px;
  pointer: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  margin-top: 20px;
`;

export const CheckBoxRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

type CellProps = {
  backgroundColor?: boolean;
};

export const Cell = styled.div<CellProps>`
  display: flex;
  align-items: center;
  padding: 6px;
  background-color: ${(props) =>
    props.backgroundColor ? Color.Destructive : "undefined"};
`;

export const HeadCell = styled(Cell)<CellProps>`
  background-color: ${(props) =>
    props.backgroundColor ? Color.Destructive : Color.MidnightBlue};
  padding: 6px;
  p {
    margin: 0;
    color: ${Color.White};
  }
`;

export const HeadCellFooter = styled(Cell)`
  background-color: ${Color.MidnightBlue};
  padding: 6px;
  p {
    margin: 0;
  }
`;

type Props = {
  showCheckInCheckOutColumns?: boolean;
};

export const TimeReportsGrid = styled.div<Props>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.showCheckInCheckOutColumns
      ? "minmax(100px, 0.8fr) minmax(120px, 1.2fr) minmax(100px, 1.5fr) minmax(100px, 2fr) minmax(80px, 0.8fr) minmax(100px, 1fr) minmax(70px, 0.7fr) minmax(100px, 1fr) minmax(120px, 1.2fr) minmax(100px, 2fr)"
      : "0.8fr 1fr 2fr 0.8fr 1fr 0.7fr 1fr 1fr 1fr"};
  margin: 10px 0;

  p {
    margin: 0;
    //color: ${Color.White};
  }
`;

export const PersonnelCarrierGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr;
  margin: 0px 0;

  p {
    margin: 0;
    //color: ${Color.White};
  }
`;

export const PersonnelCarrierDateGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr 2fr 1fr;
  margin: 0px 0;

  p {
    margin: 0;
    //color: ${Color.White};
  }
`;

export const TimeReportsGridBody = styled.div<Props>`
  background-color: ${Color.SeaBlue200};
  display: grid;
  grid-template-columns: ${(props) =>
    props.showCheckInCheckOutColumns
      ? "minmax(100px, 0.8fr) minmax(120px, 1.2fr) minmax(100px, 1.5fr) minmax(100px, 2fr) minmax(80px, 0.8fr) minmax(100px, 1fr) minmax(70px, 0.7fr) minmax(100px, 1fr) minmax(120px, 1.2fr) minmax(100px, 2fr)"
      : "0.8fr 1fr 2fr 0.8fr 1fr 0.7fr 1fr 1fr 1fr"};
  margin: 10px 0;

  p {
    margin: 0;
    //color: ${Color.White};
  }
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const PersonnelCarrierGridBody = styled(PersonnelCarrierGrid)`
  background-color: ${Color.SeaBlue200};

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const TimeReportsGridFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr auto auto;
  justify-content: space-between;

  p {
    margin: 0px;
  }
`;

export const WorkerTimeReportContainer = styled.div`
  margin-bottom: 70px;
`;
export const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Row = styled.div`
  align-self: end;
  display: flex;
  align-items: end;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  p {
    margin: 0;
  }
  div {
    margin: 0;
    padding: 0 0 0 5px;
  }
`;

export const NoTimeReportsWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const TotalTimeWrapper = styled.div`
  padding: 0 10px;
  display: inline-block;
  background-color: ${Color.MidnightBlue};
  margin-bottom: 20px;
`;

export const TotalCostWrapper = styled.div`
  padding: 0 10px;
  display: inline-block;
  background-color: ${Color.MidnightBlue};
  margin-bottom: 20px;
  max-height: 80px;
`;

export const PersonnelCarrierWrapper = styled.div`
  padding: 0 10px;
  display: inline-block;
  background-color: ${Color.MidnightBlue};
  margin-bottom: 20px;
`;

export const CircularProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

export const RoleWrapper = styled.div`
  background-color: ${Color.MidnightBlue};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  &:hover {
    background-color: ${Color.SeaBlue400};
  }
`;

export const RoleText = styled(Text)`
  color: ${Color.White};
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
