import React, { useState, useEffect } from "react";
import { ProfileSettings } from "./profileSettings/profileSettings.component";
import { DetailsSettings } from "./detailsSettings/detailsSettings.component";
import { CompanyUsers } from "./companyUsers/companyUsers.component";
import { CompanySettingsMobileView } from "./companySettingsMobileView/companySettingsMobileView.component";
import { CompanySettingsDesktopView } from "./companySettingsDesktopView/companySettingsDesktopView.component";
import { PaymentsCompany } from "web-apps/company/pages/payments/payments";
import { ObSettings } from "./ObSettings/obSettings.component";
import { CompanySubscription } from "./companySubscription/companySubscription.component";
import { CompanyStampClock } from "./companyStampClock/companyStampClock.component";

export const CompanySettings = () => {
  const params = new URLSearchParams(window.location.search);
  const slide = params.get("page");
  const [page, setPage] = useState(0);
  const [view, setView] = useState<string>();

  const getSlide = () => {
    switch (slide) {
      case "profile":
        return <ProfileSettings view={view} />;
      case "details":
        return <DetailsSettings view={view} />;
      case "users":
        return <CompanyUsers view={view} />;
      case "payments":
        return <PaymentsCompany />;
      case "Ob":
        return <ObSettings view={view} />;
      case "Subscription":
        return <CompanySubscription />;
        case "StampClock":
        return <CompanyStampClock />;
      default:
        if (view === "desktop") {
          return <ProfileSettings view={view} />;
        } else {
          return <></>;
        }
    }
  };

  const checkViewWidth = () => {
    if (window.innerWidth >= 768) {
      setView("desktop");
    } else {
      setView("mobile");
    }
  };

  useEffect(() => {
    checkViewWidth();

    window.addEventListener("resize", checkViewWidth);

    return () => {
      window.removeEventListener("resize", checkViewWidth);
    };
  }, []);

  useEffect(() => {
    if (slide) {
      setPage(1);
    } else {
      setPage(0);
    }
  }, [slide]);

  return (
    <>
      {view === "mobile" ? (
        <CompanySettingsMobileView page={page} setPage={setPage} slide={slide}>
          {getSlide()}
        </CompanySettingsMobileView>
      ) : (
        <CompanySettingsDesktopView setPage={setPage}>
          {getSlide()}
        </CompanySettingsDesktopView>
      )}
    </>
  );
};
